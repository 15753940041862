import { Link } from "gatsby";
import React, { Fragment } from "react";
import closeIc from "../../resources/assets/close.svg";
import burgerIc from "../../resources/assets/burger.svg";
import arrow from "../../resources/assets/arr-right.svg";
import { Menu, Transition } from "@headlessui/react";
import Footer from "./footer";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const menu = [
  { label: "Team", link: "/team" },
  { label: "Projects", link: "/projects" },
  { label: "Publications", link: "/publications" },
  { label: "Updates", link: "/updates" },
  { label: "Contacts", link: "/contacts" },
];

const Layout = ({ children }) => (
  <div className="min-h-screen flex flex-col">
    <Helmet>
      <title>Astran Lab</title>
    </Helmet>
    <header
      className="sticky top-0 bg-white shadow-md"
      style={{ zIndex: "800" }}
    >
      <div className="as-cont py-2 px-6 flex justify-between items-center">
        <Link to="/">
          <StaticImage
            src="../../resources/assets/fav.png"
            layout="fixed"
            loading="eager"
            height={70}
            quality={100}
            placeholder="tracedSVG"
            alt="Astran Lab"
          />
        </Link>
        <div>
          <nav className="hidden md:flex items-center gap-4">
            {menu.map((m) => (
              <Link
                title={"go to " + m.label.toLowerCase()}
                key={m.link}
                className="px-2 py-1 hover:bg-gray-200 transition-all rounded-md"
                to={m.link}
              >
                {m.label}
              </Link>
            ))}
          </nav>
          <MenuMob />
        </div>
      </div>
    </header>
    <main id="main" className="flex-grow bg-stone-50">
      {children}
    </main>
    <Footer />
  </div>
);

const MenuMob = () => (
  <Menu as="div" className="md:hidden flex items-center">
    {({ open }) => (
      <>
        <Menu.Button>
          <img src={open ? closeIc : burgerIc} alt="Toggle mobile menu" />
        </Menu.Button>

        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ maxHeight: "calc(100vh - 68px)" }}
            className="w-full overflow-y-auto border-t border-ci-beige-50 absolute top-full right-0 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {menu.map((m) => (
              <Link
                title={"go to " + m.label.toLowerCase()}
                key={m.link}
                className="px-6 py-4 flex items-center justify-between border-b border-gray-400"
                to={m.link}
              >
                {m.label}
                <img src={arrow} alt="" />
              </Link>
            ))}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export default Layout;
