import React from "react";
import tw from "../../resources/assets/008-twitter.svg";
import li from "../../resources/assets/027-linkedin.svg";
import ig from "../../resources/assets/029-instagram.svg";

const navigation = [
  {
    name: "Linkedin",
    href: "https://www.linkedin.com/company/astran-lab",
    icon: li,
  },
  {
    name: "Instagram",
    href: "https://instagram.com/astranlab",
    icon: ig,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/astranlab",
    icon: tw,
  },
];

export default function Footer() {
  return (
    <footer className="bg-black text-gray-200 relative z-20">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 flex flex-wrap justify-center md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center gap-4 bg-gray-200 shadow-sm rounded-full py-4 px-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={item.icon}
                alt={`follow ASTRAN Lab on ${item.name}`}
                className="w-10"
              />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1 w-full md:w-auto">
          <p className="text-center md:text-xl">
            &copy; {new Date().getFullYear()} ASTRAN Laboratory. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
